import {PageProps} from 'gatsby';
import React from 'react';
import DefaultLayout from '../layouts/default';
import HfSliderHero, {
  ViewModel as SliderHeroViewModel,
} from '../components/hf-slider-hero';
import heroImage from '../images/automobile-solution-hero.jpg';
import solution1Image from '../images/automobile-solution-1.jpg';
import solution2Image from '../images/automobile-solution-2.jpg';
import solution3Image from '../images/automobile-solution-3.jpg';
import SectionSolutions, {
  ViewModel as SectionSolutionsViewModel,
} from '../components/section-solutions';
import HfSeo from '../components/hf-seo';

const HERO_DATA: SliderHeroViewModel = [
  {
    title: '汽车行业',
    imageUrl: heroImage,
  },
];
const SOLUTIONS_DATA: SectionSolutionsViewModel = [
  {
    requirement:
      '汽车零部件生产厂家，需要在静止状态下读取激光蚀刻在电机转子端面上的二维码',
    solution: '120万像素BRV-660读码器',
    image: solution1Image,
  },
  {
    requirement:
      '发动机或变速箱上都打有一个二维码，为针打DPM码，内含此产品的身份信息。固定位置静止条件下读取此二维码，上传数据库',
    solution: '120万像素BRV-660读码器',
    image: solution2Image,
  },
  {
    requirement:
      '客户为汽车新能源电池生产商，在电池输送过程中，每一个托盘对应一块新能源电池，每个托盘上贴有1D条码标签，读取托盘上的一维码，以以太网通讯方式上传客户数据库系统。条码长40mm，标签在托盘上位置有10mm的变化，远近20mm变化',
    solution: '120万像素BRV-660读码器',
    image: solution3Image,
  },
];

export default function AutomobileSolution({location}: PageProps) {
  return (
    <DefaultLayout location={location}>
      <HfSliderHero vm={HERO_DATA} />
      <SectionSolutions vm={SOLUTIONS_DATA} />
    </DefaultLayout>
  );
}

export const Head = () => <HfSeo title="汽车工业 | 唯视智能" />;

import React, {useCallback, useState} from 'react';
import {Col, Divider, Drawer, Row} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import * as styles from './index.module.scss';
import clsx from 'clsx';
import {Link} from 'gatsby';
import HfTitle from '../hf-title';
import {GAP_SIZE_2, GAP_SIZE_3, GAP_SIZE_BASE} from '../../constants';

export type ViewModel = {
  requirement?: string;
  solution?: string;
  image: string;
}[];
type PropTypes = {
  vm: ViewModel;
};

export default function SectionSolutions({vm}: PropTypes) {
  return (
    <div className={styles.container}>
      {vm.map(({requirement, solution, image}) => (
        <div className={styles.solution}>
          <div className={styles.cover}>
            <img src={image} alt="" />
          </div>
          <div className={styles.content}>
            {requirement && (
              <>
                <div className={styles.tag}>需求描述</div>
                <div>{requirement}</div>
              </>
            )}
            {solution && (
              <>
                <div className={styles.tag}>解决方案</div>
                <div>{solution}</div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
